import * as React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {ModifiedToast} from "../../components/toaster";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
// import {commonErrorBag, getEditData, saveForm, saveProfile} from "../../helpers/helpers";
import {batch, useDispatch, useSelector} from "react-redux";
import {bannerGetEditAction, bannerRemoveAction} from "../../actions/bannerActions";
import {LoadingIndicator} from "../../components/loading-indicator";
import jQuery from 'jquery'
import {bannerCreateEditAction} from "../../actions/bannerActions";
import {countryListsAction} from "../../actions/countryActions";
import {discountListsAction} from "../../actions/discountActions";

function BannerNew() {
    var loading = false
return (
    <>
        <HelmetProvider>
                <Helmet>
                    <title>Right Hands | Banner List</title>
                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>
        <div className='w-100 py-6 md:px-10 px-3'>
            <h1 className="text-2xl mb-3 font-normal">Banner Update</h1>

            <div className="card dark:bg-gray-700 bg-white md:p-8 p-4 mb-3 ">

                    <div className="formArea mt-5 ">
                        <p>Country *</p>
                        <select>
                            <option>India</option>
                            <option>United Arab Emirates</option>
                            <option>Dubai</option>
                        </select>

                        <p>Image</p>
                        <p>TODO: add image upload option</p>
                        <p>Description(English)*</p>
                        <input placeholder='Banner description in english'></input>
                        <p>Description(Arabic)*</p>
                        <input placeholder='Banner description in arabic'></input>
                        <p>Discount Code</p>
                        <select>
                            <option>5-Per-Off</option>
                        </select>
                        <div className="w-full mt-8">
                                <div className="form-group border-t pt-8 pb-3 ">
                                    <button
                                        type="submit"
                                        className="px-8 rounded py-3 hover:bg-blue-900 sm:w-36 w-full bg-purple-700 text-white sm:mr-2 md:mb-0 mb-2"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        // onClick={() => navigate("/banner/list")}
                                        className="px-8 rounded py-3 hover:bg-red-500 sm:w-36 w-full bg-red-500 text-white"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                    </div>
            </div>
        </div>
    </>
)
}

export default BannerNew