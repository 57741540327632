import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const countryListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_COUNTRY_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_COUNTRY_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_COUNTRY_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const countryDeleteAction = (baseUrl, listUrl) => dispatch => {
    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_COUNTRY_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_COUNTRY_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_COUNTRY_REQUEST'})
                        dispatch(countryListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_COUNTRY_FAILED'})
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const countryStatusAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_COUNTRY_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_COUNTRY_SUCCESS'})
            if (res.data.user.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_COUNTRY_REQUEST'})
            dispatch(countryListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_COUNTRY_FAILED'})
                dispatch({type: 'GET_COUNTRY_REQUEST'})
                dispatch(countryListsAction(listUrl))
                commonCatchBlock(err)
            }
        )

}

export const countryImageRemoveAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_COUNTRY_REQUEST'})
    axios.delete(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_COUNTRY_SUCCESS'})

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            dispatch({type: 'EDIT_COUNTRY_SUCCESS', payload: res.data.country})
            // dispatch(countryGetEditAction(listUrl))

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_COUNTRY_FAILED'})
                commonCatchBlock(err)
                dispatch(countryGetEditAction(listUrl));
            }
        )
}

export const countryGetEditAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_COUNTRY_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),

    })
        .then((res) => {
            dispatch({type: 'EDIT_COUNTRY_SUCCESS', payload: res.data.country})
        })
        .catch((err) => {
                dispatch({type: 'GET_COUNTRY_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const countryCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId) => dispatch => {

    dispatch({type: 'GET_COUNTRY_REQUEST'})
    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    let frm = jQuery(_form);
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {

            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = "/country/list"
                dispatch({type: 'CREATE_COUNTRY_SUCCESS', payload: response.data.country})
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_COUNTRY_FAILED'})
                commonCatchBlock(err, frm)
                if (getId) {
                    dispatch(countryGetEditAction(getUrl));
                }
            }
        )
}