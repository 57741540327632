import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const discountListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_DISCOUNT_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_DISCOUNT_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_DISCOUNT_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const setDiscountList = (data) => dispatch => {

    dispatch({type: 'GET_DISCOUNT_SUCCESS', payload: {data: data}})
}

export const discountDeleteAction = (baseUrl, listUrl) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_DISCOUNT_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_DISCOUNT_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_DISCOUNT_REQUEST'})
                        dispatch(discountListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_DISCOUNT_FAILED'})
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const discountStatusAction = (baseUrl, dataSet = {}, listUrl, status = false) => dispatch => {

    dispatch({type: 'UPDATE_DISCOUNT_REQUEST'})
    axios.put(baseUrl, dataSet, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_DISCOUNT_SUCCESS'})
            if (!status) {
                if (res.data.user.isActive) {
                    toast.success(res.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    })
                } else {
                    toast.error(res.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    })
                }
            } else {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_DISCOUNT_REQUEST'})
            dispatch(discountListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_DISCOUNT_FAILED'})
                // dispatch({type: 'GET_DISCOUNT_REQUEST'})
                // dispatch(discountListsAction(listUrl))
                commonCatchBlock(err)
            }
        )

}

export const discountGetEditAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_DISCOUNT_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'EDIT_DISCOUNT_SUCCESS', payload: res.data.discount})
        })
        .catch((err) => {
                dispatch({type: 'GET_DISCOUNT_FAILED'})
                commonCatchBlock(err)
            }
        )

}

// export const discountGetServiceAction = (baseUrl) => dispatch => {
//
//     dispatch({type: 'GET_DISCOUNT_REQUEST'})
//     axios.get(baseUrl, {
//         headers: authHeader(baseUrl),
//     })
//         .then((res) => {
//             dispatch({type: 'EDIT_DISCOUNT_SUCCESS', payload: res.data.services})
//         })
//         .catch((err) => {
//                 if (err.response.status === 401) {
//  destroyTokenDetails()
//                     window.location.href = "/"
//                 }
//                 toast.error(err.response.data.message, {
//                     duration: 2000,
//                     position: 'top-right',
//                 })
//                 dispatch({type: 'GET_DISCOUNT_FAILED'})
//             }
//         )
//
// }

export const discountCreateEditAction = (baseUrl, method, _form, getUrl, getId) => dispatch => {
    let frm = jQuery(_form);
    dispatch({type: 'GET_DISCOUNT_REQUEST'})
    try {
        axios({
            url: baseUrl, data: frm.serialize(), method: method,
            headers: authHeader(baseUrl),
        })
            .then((response) => {
                toast.success(response.data.message, {
                    duration: 2000,
                    position: 'top-right',
                });
                setTimeout(() => {
                    window.location.href = "/discount/list"
                    dispatch({type: 'CREATE_DISCOUNT_SUCCESS', payload: response.data.service})
                }, 1000)
            })
            .catch((err) => {

                    dispatch({type: 'GET_DISCOUNT_FAILED'})
                    commonCatchBlock(err, frm)
                    if (getId) {
                        dispatch(discountGetEditAction(getUrl));

                    }
                }
            )
    } catch (ex) {
        dispatch({type: 'GET_DISCOUNT_FAILED'})
    }
}