import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const bannerListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_BANNER_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_BANNER_SUCCESS', payload: res.data.banners})
        })
        .catch((err) => {
                dispatch({type: 'GET_BANNER_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const bannerDeleteAction = (baseUrl, listUrl) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_BANNER_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_BANNER_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        window.location.reload();
                        window.scrollTo({
                            top: 0, 
                            behavior: 'smooth'
                        })
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_BANNER_FAILED'})
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const bannerStatusAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_BANNER_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_BANNER_SUCCESS'})
            if (res.data.banner.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }
            dispatch({type: 'GET_BANNER_REQUEST'})
            dispatch(bannerListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_BANNER_FAILED'})
                commonCatchBlock(err)

            }
        )

}

export const bannerRemoveAction = (baseUrl, pictureData, listUrl) => dispatch => {

    dispatch({type: 'GET_BANNER_REQUEST'})
    axios.put(baseUrl, {profileOne: pictureData}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_BANNER_SUCCESS'})

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            dispatch(bannerGetEditAction(listUrl))

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_BANNER_FAILED'})
                commonCatchBlock(err)
            }
        )
}

export const bannerGetEditAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_BANNER_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),

    })
        .then((res) => {
            dispatch({type: 'EDIT_BANNER_SUCCESS', payload: res.data.banner})
        })
        .catch((err) => {
                dispatch({type: 'GET_BANNER_FAILED'})
                commonCatchBlock(err)

            }
        )

}

export const bannerCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId) => dispatch => {

    dispatch({type: 'GET_BANNER_REQUEST'})
    let frm = jQuery(_form)

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((response) => {

            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            // setTimeout(() => {
            //     // window.location.href = "/banner/list"
            //     dispatch({type: 'CREATE_BANNER_SUCCESS', payload: response.data.banner})
            // }, 1000)


        })
        .catch((err) => {
                dispatch({type: 'GET_BANNER_FAILED'})
                commonCatchBlock(err, frm)
                if (getId) {
                    dispatch(bannerGetEditAction(getUrl));
                }

            }
        )

}